<!--统一用于登录注册的获取验证码-->
<template>
  <div class="get-check-code">
    <a-input v-model.trim="val" :max-length="6" type="text">
      <a-icon slot="prefix" type="mobile" />
    </a-input>
    <a-button type="link" class="get-checkcode" :disabled="!canUse" @click="getCheckCodeFunc">{{
      checkCodeText
    }}</a-button>
  </div>
</template>

<script>
export default {
  name: 'GetCheckCode',
  model: {
    prop: 'defaultVal',
    event: 'echoVal'
  },
  props: {
    defaultVal: {
      type: [String, Number],
      default: ''
    },
    canUse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      val: '',
      getCheckCoding: false,
      copyGetCheckCodeTime: 0
    }
  },
  computed: {
    checkCodeText() {
      return this.getCheckCoding ? `已发送（${this.copyGetCheckCodeTime}s）` : '获取验证码'
    }
  },
  watch: {
    val() {
      this.$emit('echoVal', this.val)
    }
  },
  mounted() {
    this.val = this.defaultVal ? JSON.parse(JSON.stringify(this.defaultVal)) : ''
    this.copyGetCheckCodeTime = this.$store.state.setting.checkCodeTime
  },
  methods: {
    getCheckCodeFunc() {
      if (this.getCheckCoding) return
      this.$emit('getCode', this.cutDown)
    },
    cutDown() {
      this.getCheckCoding = true
      const timer = setInterval(() => {
        if (this.copyGetCheckCodeTime > 0) {
          this.copyGetCheckCodeTime -= 1
        } else {
          clearInterval(timer)
          this.getCheckCoding = false
          this.copyGetCheckCodeTime = 60
        }
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.get-check-code {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .get-checkcode {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "get-check-code" },
    [
      _c(
        "a-input",
        {
          attrs: { "max-length": 6, type: "text" },
          model: {
            value: _vm.val,
            callback: function ($$v) {
              _vm.val = typeof $$v === "string" ? $$v.trim() : $$v
            },
            expression: "val",
          },
        },
        [
          _c("a-icon", {
            attrs: { slot: "prefix", type: "mobile" },
            slot: "prefix",
          }),
        ],
        1
      ),
      _c(
        "a-button",
        {
          staticClass: "get-checkcode",
          attrs: { type: "link", disabled: !_vm.canUse },
          on: { click: _vm.getCheckCodeFunc },
        },
        [_vm._v(_vm._s(_vm.checkCodeText))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }